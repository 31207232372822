import { Container, Grid } from '@mui/material'
import React from 'react'
import axios from 'axios'
import './contact.css'
import { useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress';
import contactgif from '../../assets/contact/call-center.gif'
import { useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const Contact = () => {

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const formik = useFormik({

        initialValues: {
            name: '',
            phone: '',
            email: '',
            message: '',
        },

        onSubmit: (values, actions) => {
            setLoading(true)
            axios({
                method: "POST",
                url: "https://formspree.io/f/xpznjvkp",
                data: values
            }).then(response => {
                actions.setSubmitting(false)
                actions.resetForm()
                setLoading(false)
                handleClick();
            }).catch(error => {
                actions.setSubmitting(false)
                setLoading(false)
            });
        },

    });

    return (
        <Container className='linescolors__contact-container'>
            <Grid container direction='row' alignItems='center' justifyContent='center'>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid container direction='column' alignItems='flex-start' className='linescolors__contact-container__content'>
                        <Grid item>
                            <h4 className='highlight'>Contact Us</h4>
                            <h1 className='gradient__text'>How can we help you?</h1>
                            <p>Send an email or fill in the form</p>
                            <img src={contactgif} alt='contact' width={350} height={370} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <form onSubmit={formik.handleSubmit} className='linescolors__contact-form'>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder='Name'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <input
                            id="phone"
                            name="phone"
                            type="text"
                            placeholder='Phone Number'
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder='Email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        <textarea
                            id="message"
                            name="message"
                            type="text"
                            placeholder='Message'
                            onChange={formik.handleChange}
                            value={formik.values.message}
                        />
                        <button type="submit">{loading ? <CircularProgress size='2rem' sx={{color: 'white'}} /> : 'Submit'}</button>
                    </form>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Your message sent successfully"
                action={action}
            />
        </Container>
    )
}

export default Contact