import { Grid } from '@mui/material'
import React from 'react'
import './service.css'

const Service = ({image, heading, para}) => {
    return (
        <Grid container direction='column' alignItems='flex-start' className='linescolors__service-container'>
            <Grid item>
                <img src={image} alt='businesscards' />
            </Grid>
            <Grid item>
                <h2>{heading}</h2>
            </Grid>
            <Grid item>
                <p>{para}</p>
            </Grid>
        </Grid>
    )
}

export default Service