import { Container, Grid } from '@mui/material'
import React from 'react'
import './packages.css'
import { Packs } from '../../components'
import birthday from '../../assets/packages/birthday.png'
import party from '../../assets/packages/party.png'
import anniversary from '../../assets/packages/anniversary.png'

const Packages = () => {

    const packages = [
        {
            img: birthday,
            heading: 'Birthday Packages',
            content: 'Celebrate every life on the planet. Birthdays are very special. Make them special with our exclusive birthday packages.'
        },
        {
            img: anniversary,
            heading: 'Aniversary Packages',
            content: ' Get your loved ones their favourite color and designs printed. Make your love life even more colourful with our anniversary packages.'
        },
        {
            img: party,
            heading: 'Party Packages',
            content: 'Occasions are more remembered with the return gifts and unique items on the table. Make your party a banger with our snobby gift items.'
        }
    ]

    return (
        <Container className='linescolors__packages-container'>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    <h1 className='gradient__text'>Our Packages</h1>
                </Grid>
                <Grid item>
                    <Grid container direction='row' spacing={8} alignItems='center'>
                        {
                            packages.map((pack) => (
                                <Grid item lg={4} md={4} sm={4}>
                                    <Packs img={pack.img} heading={pack.heading} content={pack.content}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Packages