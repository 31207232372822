import React, { useState } from 'react'
import './navbar.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { Paper } from '@mui/material'
import Container from '@mui/material/Container';

const Menu = ({onClick}) => {
    return (
        <>
            <p><a href='#home'>Home</a></p>
            <p onClick={() => {onClick('about')}}><a href='#about'>About Us</a></p>
            <p onClick={() => {onClick('service')}}><a href='#services'>Services</a></p>
            <p onClick={() => {onClick('package')}}><a href='#packages'>Packages</a></p>
            <p onClick={() => {onClick('contact')}}><a href='#contact'>Contact Us</a></p>
        </>
    )
}

const Navbar = ({onClick}) => {

    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <Container>
            <div className='linescolors__navbar-container'>
                <div className='linescolors__navbar-container__icon'>
                    <p>Lines & Colors</p>
                </div>
                <div className='linescolors__navbar-container__links'>
                    <Menu onClick={(id) => {onClick(id)}}/>
                </div>
                <div className='linescolors__navbar-container__togglemenu'>
                    {
                        toggleMenu ?
                            <RiCloseLine color='#34495e' size={27} onClick={() => setToggleMenu(false)} />
                            :
                            <RiMenu3Line color='#34495e' size={27} onClick={() => setToggleMenu(true)} />
                    }
                    {
                        toggleMenu && (
                            <Paper elevation={3} className='linescolors__navbar-container__togglemenu__menu'>
                                <Menu onClick={(id) => {onClick(id)}}/>
                            </Paper>
                        )
                    }
                </div>
            </div>
        </Container>
    )
}

export default Navbar