import { Container } from '@mui/material'
import Grid from '@mui/material/Grid';
import React from 'react'
import './doings.css'
import business from '../../assets/doings/Business_Cards.png'
import posters from '../../assets/doings/Posters.png'
import birthday from '../../assets/doings/Birthday_Parties.png'
import tshirt from '../../assets/doings/Custom_T-shirts.png'
import mugs from '../../assets/doings/Printed_Mugs.png'

const Doings = () => {
  return (
    <Container className='linescolors__doings-container'>
        <Grid container spacing={1} direction='column' alignItems='center' justifyContent='center'>
            <Grid item marginBottom={3}>
                <h1>What we do</h1>
            </Grid>
            <Grid item>
                <Grid container spacing={5} direction='row' alignItems='center' justifyContent='center'>
                    <Grid item>
                        <img src={business} alt='business' />
                    </Grid>
                    <Grid item>
                        <img src={posters} alt='posters' />
                    </Grid>
                    <Grid item>
                        <img src={birthday} alt='birthday' />
                    </Grid>
                    <Grid item>
                        <img src={tshirt} alt='tshirt' />
                    </Grid>
                    <Grid item>
                        <img src={mugs} alt='mugs' />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
  )
}

export default Doings