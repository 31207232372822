import { Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Service } from '../../components'
import Grow from '@mui/material/Grow'
import './services.css'
import businesscard from '../../assets/services/businesscard.jpg'
import banners from '../../assets/services/banners.jpg'
import brochures from '../../assets/services/brochures.jpg'
import calender from '../../assets/services/calender.jpg'
import postcards from '../../assets/services/postcards.png'
import flyers from '../../assets/services/flyers.jpg'

const Services = () => {

    const [click, setClick] = useState(false)

    const services = [
        {
            image: businesscard,
            heading: 'Business Cards',
            para: 'Our #1 bestselling product! Choose from over 20 different types of high quality business cards.'
        },
        {
            image: banners,
            heading: 'Banners',
            para: 'Our banners come in standard and custom sizes with optional hemming and grommets.'
        },
        {
            image: brochures,
            heading: 'Brochures',
            para: 'Get your printing, bindery and bundling done all in one place for brochures and pamphlets.'
        },
    ]

    const moreServices = [
        {
            image: calender,
            heading: 'Calenders',
            para: 'Get quality calenders in different styles and formats. We provide unique designs and patterns'
        },
        {
            image: postcards,
            heading: 'Postcards',
            para: 'Print postcards wholesale with custom sizes, unique stocks, premium finishes and more.'
        },
        {
            image: flyers,
            heading: 'Flyers',
            para: 'Print flyers quickly and affordably without sacrificing quality. Bulk quantities available.'
        }
    ]
    return (
        <Container className='linescolors__services-container'>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    <h1 className='gradient__text'>Our Services</h1>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} direction='row' alignItems='center'>
                        {
                            services.map((item) => (
                                <Grid item>
                                    <Service image={item.image} heading={item.heading} para={item.para} />
                                </Grid>
                            ))
                        }
                        {
                            click &&
                            moreServices.map((item) => (
                                <Grow in={click} style={{marginTop: '24px'}}>
                                    <Grid item>
                                        <Service image={item.image} heading={item.heading} para={item.para} />
                                    </Grid>
                                </Grow>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item alignSelf='flex-end'>
                    <p style={{cursor: 'pointer'}} onClick={() => { setClick(true) }}>{click ? 'and more' : 'view more'}...</p>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Services