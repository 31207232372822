import React from 'react'
import './header.css'
import headerimg from '../../assets/headerimg.png'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

const Header = ({ onClick }) => {
    return (
        <Container>
            <Grid container spacing={1} direction='row' marginTop={8}>
                <Grid item lg={6} sm={12} xs={12} md={6} className='linescolors__header-container__content'>
                    <h1>Ever wondered where to get the best <span className='highlight'>print jobs</span> done around Toronto? You have reached the <span className='highlight'>right</span> place…</h1>
                    <p>Lines and Colors is a Canadian based printing company. We get your brochures, flyers, envelopes, letter-heads, coffee cups, greeting cards and what not,
                        printed in the fastest time possible with no compromise in the quality.
                        We provide custom made or our readymade word class design layouts for printing.</p>
                    <div className='linescolors__header-container__gradient__btn'><p onClick={() => { onClick('service') }}><a href='#about'>Explore</a></p></div>
                </Grid>
                <Grid item lg={6} sm={12} xs={12} md={6} className='linescolors__header-container__img'>
                    <img src={headerimg} alt='headerImage' />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Header