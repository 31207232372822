import React, { useRef } from 'react'
import './App.css'
import { Footer, Navbar } from './components'
import { AboutCards, Doings, Header, AboutPosters, Packages, Contact, Services } from './containers'

const App = () => {

    const headerRef = useRef(null)
    const aboutRef = useRef(null)
    const serviceRef = useRef(null)
    const packageRef = useRef(null)
    const contactRef = useRef(null)

    const handleClick = (id) => {
        switch (id) {
            case 'header':
                headerRef.current.scrollIntoView({behavior: 'smooth'})
                break;
            case 'about': 
                aboutRef.current.scrollIntoView({behavior: 'smooth'})
                break;
            case 'service':
                serviceRef.current.scrollIntoView({behavior: 'smooth'})
                break;
            case 'package':
                packageRef.current.scrollIntoView({behavior: 'smooth'})
                break;
            case 'contact':
                contactRef.current.scrollIntoView({behavior: 'smooth'})
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div ref={headerRef} className='header_gradient_bg'>
                <Navbar onClick={(id) => {
                    handleClick(id)
                }} />
                <Header onClick={(id) => {
                    handleClick(id)
                }}/>
            </div>
            <div className='doings_bg'>
                <Doings />
            </div>
            <div ref={aboutRef} className='about_gradient_bg '>
                <AboutCards />
            </div>
            <div className='about_posters_bg'>
                <AboutPosters />
            </div>
            <div ref={serviceRef} className='service_bg'>
                <Services />
            </div>
            <div ref={packageRef} className='packages_bg'>
                <Packages />
            </div>
            <div ref={contactRef} className='contact_bg'>
                <Contact />
            </div>
            <div className='footer_bg'>
                <Footer onClick={(id) => {
                    handleClick(id)
                }}/>
            </div>
        </>
    )
}

export default App
