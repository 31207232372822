import { Grid } from '@mui/material'
import React from 'react'
import './description.css'
import { FaCheckDouble } from 'react-icons/fa'

const Description = ({ heading, para, items }) => {
    return (
        <Grid container direction='column' spacing={2} className='linescolors__description-container'>
            <Grid item>
                <h1 className='gradient__text'>{heading}</h1>
            </Grid>
            <Grid item>
                <p>{para}</p>
            </Grid>
            {
                items.map((item) => (
                    <Grid item>
                        <Grid container direction='row' spacing={1} alignItems='center' justifyContent='flex-start' className='linescolors__description-container__items'>
                            <Grid item md={1} sm={1} xs={1}>
                                <FaCheckDouble color='#FC5C7D'/>
                            </Grid>
                            <Grid item md={11} sm={11} xs={11}>
                                <h1 className='highlight'>{item.head}</h1>
                                <p>{item.desc}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Description