import { Container, Grid } from '@mui/material'
import React from 'react'
import './aboutcards.css'
import { Description } from '../../components'
import man from '../../assets/about/man.png'

const AboutCards = () => {

    const heading = 'We know you are the boss. We customize  the print in a way our boss is satisfied and happy'
    const para = 'Our team can assist you in creating your design. Tell us your inspiration and printing guidelines and we will give you unique prints for your products, that will leave a life long impression.'
    const items = [
        {head: 'Plain-spoken', desc: 'We think that when we communicate honestly and openly, we can share and learn from one another'},
        {head: 'Guaranteed Quality', desc: 'We make sure that we don’t apologize for an inferior quality of your prints'},
        {head: 'Industry Standard Pricing', desc: 'High quality doesn’t make us expensive. Contact us for customized quote'}
    ]

  return (
    <Container className='linescolors__about-container'>
        <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
            <Grid item lg={6} md={6} className='linescolors__about-container__img'>
                <img src={man} alt='man'/>
            </Grid>
            <Grid item lg={6} md={6}>
                <Description heading={heading} para={para} items={items}/>
            </Grid>
        </Grid>
    </Container>
  )
}

export default AboutCards