import React from 'react'
import { Container, Grid } from '@mui/material'
import './aboutposters.css'
import { Description } from '../../components'
import posters from '../../assets/about/posters.png'

const AboutPosters = () => {
    const heading = 'We use the best printing materials at unmatched price'
    const para = 'Since we are aware that you want the most creative, vibrant, and attention-grabbing promotional materials available, we only utilize the best materials.'
    const items = [
        {head: 'Professionalism', desc: 'We take it seriously, that’s what pays us and makes us better everyday.'},
        {head: 'Creativity', desc: 'Each mind has new perspectives. Tell us yours we will print it.'},
        {head: 'Unmatched Pricing', desc: 'More the print count, better will be the pricing. Don’t hesitate for bulk orders.'}
    ]

  return (
    <Container className='linescolors__aboutposters-container'>
        <Grid container direction='row' alignItems='center' justifyContent='center'>
            <Grid item lg={6} md={6}>
                <Description heading={heading} para={para} items={items}/>
            </Grid>
            <Grid item lg={6} md={6} className='linescolors__aboutposters-container__img'>
                <img src={posters} alt='posters'/>
            </Grid>
        </Grid>
    </Container>
  )
}

export default AboutPosters