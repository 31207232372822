import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from "react-router-dom";
import './footer.css'

const Footer = ({onClick}) => {
    return (
        <Container>
            <Grid container direction='column' className='linescolors__footer-container'>
                <Grid item>
                    <Grid container direction='row' alignItems='flex-start' justifyContent='space-between' className='linescolors__footer-content'>
                        <Grid item>
                            <h3>Lines & Colors</h3>
                            <h1>Make your special <br />occasions Memorable</h1>
                            <p style={{cursor: 'pointer'}} onClick={() => {onClick('header')}}>Explore</p>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column'>
                                <h5>Details</h5>
                                <Link to="/company"><p>Company</p></Link>
                                <Link to="/privacy"><p>Privacy Policy</p></Link>
                                <p style={{cursor: 'pointer'}} onClick={() => {onClick('about')}}>How it work</p>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column'>
                                <h5>Get in touch</h5>
                                <p>linescolors.ca@gmail.com</p>
                                <p>+1 437-974-2289</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>

                </Grid>
            </Grid>
        </Container>
    )
}

export default Footer