import React from 'react'
import './packs.css'

const Packs = ({img, heading, content}) => {
    return (
        <div className='linescolors__packs-container'>
            <img src={img} alt='birthday' />
            <div className='linescolors__packs-container__content'>
                <h1>{heading}</h1>
                <div className='gradient_line'></div>
                <p>{content}</p>
                <div className='linescolors__packs-content__gradient_border_btn'><p>Coming Soon</p></div>
            </div>
        </div>
    )
}

export default Packs