import React from 'react'
import ReactDOM from 'react-dom'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './index.css'
import App from './App'
import { Company, Privacy } from './containers';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/company" element={<Company />} />
            <Route path="/privacy" element={<Privacy />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
)